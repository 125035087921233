import getSymbolFromCurrency from "currency-symbol-map";
import { decode } from "js-base64";
import Stripe from "stripe";

const stripe = new Stripe(
  "pk_live_51NLRa7BTvxDADvvpbmZMxEltZ8qSUVCj9JsmdFQGJSL6MOsvC48GbY4ZFCTmhvrRDMN0gnBx9tXPbMZdvTuB82gR00yLaSyrIt",
);

export const generateCardToken = async (creditCard) => {
  const { cardNumber, expiry, cvc } = creditCard;

  const expMonth = expiry.split("/")[0];
  const expYear = expiry.split("/")[1];

  const tokenData = await stripe.tokens.create({
    card: {
      number: Number(cardNumber.replace(/\s/g, "")),
      exp_month: Number(expMonth),
      exp_year: Number(expYear),
      cvc: Number(cvc),
    },
  });

  return tokenData;
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.match(/android/i)) {
    return "Android";
  } else if (userAgent.match(/iphone|ipad|ipod/i)) {
    return "iOS";
  } else if (userAgent.match(/mac/i)) {
    return "MacBook";
  } else {
    return "Computer";
  }
};

export const decodeBase64 = (str) => {
  str += Array(5 - (str.length % 4)).join("=");
  str = str.replace(/\-/g, "+").replace(/\_/g, "/");
  return atob(str);
};

// darken color
export const adjustColorBrightness = (color, amount) => {
  const parsedColor = color?.replace("#", "");
  const r = parseInt(parsedColor.substring(0, 2), 16);
  const g = parseInt(parsedColor.substring(2, 4), 16);
  const b = parseInt(parsedColor.substring(4, 6), 16);

  const adjustedR = Math.max(0, Math.min(255, r + amount));
  const adjustedG = Math.max(0, Math.min(255, g + amount));
  const adjustedB = Math.max(0, Math.min(255, b + amount));

  return `#${adjustedR.toString(16).padStart(2, "0")}${adjustedG
    .toString(16)
    .padStart(2, "0")}${adjustedB.toString(16).padStart(2, "0")}`;
};

export const confirmPayment = async (
  clientSecret,
  ev,
  stripe,
  setStep,
  setIsScrolledDown,
  dispatch,
  getPublisherDetails,
  state,
  baseURL,
  url,
  userId,
) => {
  // Confirm the PaymentIntent without handling potential next actions (yet).
  const { paymentIntent, error: confirmError } =
    await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false },
    );
  if (confirmError) {
    ev.complete("fail");
  } else {
    ev.complete("success");

    setStep("signIn");
    setIsScrolledDown(false);
    localStorage.setItem("payId:", userId);
    setTimeout(() => {
      dispatch(
        getPublisherDetails({
          publisherId: state?.publisher?.publisherDetails?.id,
          data: { articleURL: decode(url), userId: userId },
        }),
      );

      window.parent.postMessage(
        {
          purchased: state?.publisher?.publisherDetails?.purchased,
          postData: state?.publisher?.publisherDetails?.postData,
        },
        "*",
      );
    }, 2000);

    if (paymentIntent.status === "requires_action") {
      const { error, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret);
      if (error) {
        console.log(error);
      } else {
        setStep("signIn");
        setIsScrolledDown(false);
        localStorage.setItem("payId:", userId);
        setTimeout(() => {
          dispatch(
            getPublisherDetails({
              publisherId: state?.publisher?.publisherDetails?.id,
              data: { articleURL: decode(url), userId: userId },
            }),
          );

          window.parent.postMessage(
            {
              purchased: state?.publisher?.publisherDetails?.purchased,
              postData: state?.publisher?.publisherDetails?.postData,
            },
            "*",
          );
        }, 2000);
      }
    } else {
      setStep("signIn");
      setIsScrolledDown(false);
      setTimeout(() => {
        dispatch(
          getPublisherDetails({
            publisherId: state?.publisher?.publisherDetails?.id,
            data: { articleURL: decode(url), userId: userId },
          }),
        );

        window.parent.postMessage(
          {
            purchased: state?.publisher?.publisherDetails?.purchased,
            postData: state?.publisher?.publisherDetails?.postData,
          },
          "*",
        );
      }, 2000);
    }
  }
};

export const formattedCurrencies = (currencyCode) => {
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const isCurrencySymbolLetters = /[a-zA-Z]/.test(currencySymbol ?? "");

  if (isCurrencySymbolLetters && currencySymbol) {
    return currencySymbol.toUpperCase() + " ";
  } else if (currencySymbol === "$" && currencyCode.toLowerCase() !== "usd") {
    return currencyCode.slice(0, 2).toUpperCase() + currencySymbol;
  }

  return currencySymbol;
};

// This function converts a given price from a specified currency to USD
export const convertCurrencyIntoUSD = async (price, money) => {
  try {
    if(money.toLowerCase() === 'usd') return {convertedPrice: price* 100, convertedCurrency: money};
    const response = await fetch(`https://openexchangerates.org/api/latest.json?app_id=b1dd08d74d1248eab0a7e57bfe623c27&base=USD&symbols=${money}&prettyprint=false&show_alternative=false`,);
    const data = await response.json();
    if (data && data?.rates && data?.rates[money.toUpperCase()]) {
      const exchangeRate = data?.rates[money.toUpperCase()];
      price = (price / exchangeRate).toFixed(2) * 100;
      money = "usd";
    }
    return {convertedPrice: price, convertedCurrency: money};
  } catch (error) {
    console.error("Error fetching conversion rate:", error?.message);
    return {};
  }
}