import { useState, useEffect } from "react";
export const useCountryCode = () => {
  const [code, setCountryCode] = useState(undefined);

  useEffect(() => {
    const apiRequest = async () => {
      const ipResponse = await fetch(`https://api.ipify.org/?format=json`);
      const ipData=await ipResponse.json();
      const ipRequestResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
      const responseData = await ipRequestResponse.json();
      const code = responseData.country_code;
      setCountryCode(code);
    };
    apiRequest();
  }, []);

  return code;
};
