import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import WechatPay from "./wechatPay";
import AliPay from "./aliPay";

import {
  StripePayBtn,
  PayWithCard,
  PaySection,
  PayWithCardDisabled,
} from "./PaymentUIObject";

import {
  getUserDetails,
  saveCardTokenDetails,
  cardInfoCheck,
} from "../../redux/authSlice";

import LoadingBar from "../loadingBar";
import { AMPLITUDE_EVENTS } from "../../utils/text";
import { sendEvent } from "../../services/amplitude";
import usePaymentCardOptions from "../../hooks/usePaymentCardOptions";
import { PaymentContent } from "./index.style";
import { useCountryCode } from "../../hooks/useCountryCode";
import { useCheckCountryAllow } from "../../hooks/useCheckCountryAllow";
import "./../../../src/App.css";
const PaymentModal =  ({ setStep, setIsScrolledDown }) => {
  const { userProfile } = useSelector((state) => state?.auth);
  const { publisherDetails } = useSelector((state) => state?.publisher);

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const code =  useCountryCode();
  const countryCheckFlag =  useCheckCountryAllow(code);
  const cardOptions = usePaymentCardOptions();
  const { url } = useParams();

  const [loading, setLoading] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isAvailable, setAvailable] = useState(false);
  const [cardError, setCardErrorMesssage] = useState("");
  const [cardInfoStatus, setCardInfoCheck] = useState(undefined);
  const [isProgress, setProgress] = useState(undefined);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmitPaymentMethod = async (data) => {
    setCardInfoCheck(undefined);
    setProgress(true);
    if (data?.paymentCard?.error) {
      setCardErrorMesssage(data?.paymentCard?.error.message);
    }
    if (data?.paymentCard?.error === undefined) {
      setLoading(true);

      let test = await stripe.createToken(elements.getElement(CardElement));
      //  card check it is valid or not before proceeding charging
      //

      let { token } = await stripe.createToken(
        elements.getElement(CardElement),
      );

      dispatch(
        cardInfoCheck({
          data: { token: test?.token?.id, userId: userProfile?.id },
          setCardInfoCheck,
        }),
      );
      if (token) {
        sendEvent(AMPLITUDE_EVENTS.ENTERED_CC);
        const getToken = dispatch(saveCardTokenDetails({ ...token, ...data }));
        if (getToken) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (loading === false) {
      if (cardInfoStatus === true) {
        setProgress(false);
        setStep("paymentSignup");
      } else if (cardInfoStatus === false) {
        setProgress(false);
        setCardErrorMesssage("Invalid card information");
      } else {
      }
    }
  }, [cardInfoStatus, loading]);

  useEffect(() => {
    const ck = setTimeout(() => {
      if (cardError) {
        setCardErrorMesssage("");
      }
    }, 3000);
    return () => {
      clearTimeout(ck);
    };
  }, [cardError]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe?.paymentRequest({
      country: "US",
      currency: publisherDetails?.currency || "usd",
      total: {
        label: publisherDetails?.name,
        amount: Number(publisherDetails?.articlePrice) * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.on("paymentmethod", async (ev) => {
      // details API for user login
      dispatch(
        getUserDetails({
          data: {
            name: userProfile?.email?.split("@")[0],
            userId: userProfile?.id,
          },
          url,
          ev,
          stripe,
          setStep,
          setIsScrolledDown,
        }),
      );
    });

    pr.canMakePayment()
      .then((result) => {
        setAvailable(true);
        if (result) {
          setPaymentRequest(pr);
        }
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }, [dispatch, stripe, elements, url, userProfile, publisherDetails]);

  const titleLabel =
    paymentOption === "card" ? "Payment" : "Select payment method";

  // #error message
  const nameErrorElement = errors.cardHolderName ? (
    <p className="error">{errors.cardHolderName.message}</p>
  ) : null;
  const cardErrorElement = cardError ? (
    <p className="bottom">{cardError}</p>
  ) : null;

  return (
    <PaymentContent>
      <h3>{titleLabel}</h3>
      <form onSubmit={handleSubmit(onSubmitPaymentMethod)}>
        {paymentOption === "card" ? (
          <>
            <label htmlFor="card" className="cardLabel">
              Card information
            </label>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 13 }}>
                <Input.Group compact className="nameField">
                  <Controller
                    name="cardHolderName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Name is required",
                    }}
                    render={({ field }) => (
                      <Input {...field} placeholder="Name" />
                    )}
                  />
                </Input.Group>
                {nameErrorElement}
                {/* Credit card component */}
                <div className="cardComponent">
                  <Controller
                    name="paymentCard"
                    control={control}
                    render={({ field }) => (
                      <CardElement
                        {...field}
                        options={{
                          hidePostalCode: true,
                          ...cardOptions,
                        }}
                      />
                    )}
                  />
                </div>
                {cardErrorElement}
                <a
                  href="https://stripe.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="stripeBrand">
                  <p> Powered by </p> <img src="/icons/stripe.svg" alt="" />
                </a>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 13 }}>
                <Button
                  className={`cardButton`}
                  htmlType="submit"
                  type="submit">
                  {isProgress ? (
                    <LoadingOutlined className="whitespin" />
                  ) : (
                    <span className="boldLabel">Continue</span>
                  )}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <div className="paymentArea">
              <PayWithCard onClick={() => setPaymentOption("card")}>
                <img src="/icons/cardIcon.svg" alt="" />
                <span>Card</span>
              </PayWithCard>

              <label htmlFor="" className="or">
                Or
              </label>
              {isAvailable === false ? (
                <LoadingBar count={1} style={{ height: 50, width: 160 }} />
              ) : paymentRequest ? (
                <StripePayBtn>
                  <PaymentRequestButtonElement
                    onClick={() => sendEvent(AMPLITUDE_EVENTS.CLICKED_GP_AP)}
                    options={{ paymentRequest }}
                  />
                </StripePayBtn>
              ) : (
                <PaySection>
                  <PayWithCardDisabled>
                    <img src="/icons/g-pay.svg" alt="" />
                    <span> </span>
                    <img src="/icons/ios-pay.svg" alt="" />
                  </PayWithCardDisabled>
                  <p>Unavailable in this browser. </p>
                </PaySection>
                
              )}
            </div>
          </>
        )}
      

      </form>
      {paymentOption !== "card" && countryCheckFlag!==undefined && countryCheckFlag? (
        <AliPay
          user={userProfile.id}
          publisher={publisherDetails.id}
          article={url}
          setStep={setStep}
          setIsScrolledDown={setIsScrolledDown}
          price={Number(publisherDetails?.articlePrice)}
          money={publisherDetails?.currency || "USD"}
        />
      ) : null}
      {paymentOption !== "card" && countryCheckFlag!==undefined && countryCheckFlag? (
        <WechatPay
          user={userProfile.id}
          article={url}
          setStep={setStep}
          setIsScrolledDown={setIsScrolledDown}
          publisher={publisherDetails.id}
          price={Number(publisherDetails?.articlePrice) }
          money={publisherDetails?.currency || "USD"}
        />
      ) : null} 

    </PaymentContent>
  );
};

export default PaymentModal;
