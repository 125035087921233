import React, { useEffect, useState } from "react";
import { Input, notification } from "antd";
import { useParams } from "react-router-dom";
import { BiLockAlt } from "react-icons/bi";
import { Controller, useForm } from "react-hook-form";
import { convertCurrencyIntoUSD } from "../../utils/helper";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

import {
  PaymentButton,
  StripePayBtn,
  PaySection,
  PayWithCardDisabled,
} from "./paymentSuccessUi";

import LoadingBar from "../loadingBar";
import AliPay from "../payment/aliPay";
import WechatPay from "../payment/wechatPay";

import { formattedCurrencies } from "../../utils/helper";
import {
  getUserDetails,
  makePayment,
  updateUserProfile,
} from "../../redux/authSlice";
import usePaymentCardOptions from "../../hooks/usePaymentCardOptions";
import { FinalizeContent } from "./index.style";

const PaymentSuccessForm = ({
  setStep,
  setIsScrolledDown,
  publisherDetails,
  articleURL,
}) => {
  const {
    handleSubmit,
    // formState: { errors },
    // setError,
    control,
    reset,
  } = useForm();

  const [edit, setEdit] = useState(false);
  const [newEmail, setNewEmail] = useState(undefined);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [isAvailable, setAvailable] = useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const cardOptions = usePaymentCardOptions();
  const currency = formattedCurrencies(publisherDetails?.currency ?? "USD");

  const { userProfile } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.auth);
  const { url } = useParams();
  const publisherButtonStyle = {
    background: publisherDetails?.primaryColor ?? "#4209EE",
    color: publisherDetails?.secondaryColor ?? "#ffffff",
  };

  useEffect(() => {
    reset({
      email: userProfile?.email,
    });
  }, [reset, userProfile?.email]);

  const { cardTokenDetails } = useSelector((state) => state.auth);

  const onSubmitEmailCard = (data) => {
    const { cardHolderName, ...oldCardTokenData } = cardTokenDetails;

    setNewEmail(data?.email);
    if (data?.paymentCard && data?.paymentCard?.error === undefined) {
      stripe
        .createToken(elements.getElement(CardElement))
        .then((res) => {
          let updateData = {
            cardToken: res?.token?.id,
          };
          if (data?.email !== userProfile?.email) {
            updateData = {
              ...updateData,
              email: data?.email,
            };
          }
          setEdit(false);
          dispatch(updateUserProfile({ data: updateData, setStep, api }));
        })
        .catch((error) => console.log(error));
    } else {
      dispatch(
        updateUserProfile({ data: { email: data?.email }, setStep, api }),
      );
      setEdit(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!stripe || !elements) {
        return;
      }
      if(!publisherDetails || ! 'articlePrice' in publisherDetails) return;
      const {convertedPrice, convertedCurrency} = await convertCurrencyIntoUSD(Number(publisherDetails?.articlePrice), publisherDetails?.currency || "USD");
      const pr = stripe?.paymentRequest({
        country: "US",
        currency:convertedCurrency,
        total: {
          label: publisherDetails?.name,
          amount: convertedPrice,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on("paymentmethod", async (ev) => {
        // details API for user login
        dispatch(
          getUserDetails({
            data: {
              name: userProfile?.email?.split("@")[0],
              userId: userProfile?.id,
            },
            url,
            ev,
            stripe,
            setStep,
            setIsScrolledDown,
          }),
        );
      });

      pr.canMakePayment()
        .then((result) => {
          setAvailable(true);
          if (result) {
            setPaymentRequest(pr);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    };
    fetchData();
  }, [dispatch, stripe, elements, url, userProfile, publisherDetails, setStep, setIsScrolledDown]);

  return (
    <FinalizeContent>
      {contextHolder}
      <div>
        <h3>Finalize payment</h3>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmitEmailCard)}>
          <div className="paymentCard">
            <div className="labelHedings">
              <label>Email</label>
              <label>Payment</label>
            </div>
            <div className={`${edit ? "paymentEdit" : "paymentData"} `}>
              {edit ? (
                <>
                  <div className="editEmail">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="email"
                          className="updateEmail"
                          placeholder="your@email.com"
                        />
                      )}
                    />
                  </div>

                  <span className="divider"></span>
                  <div className="editPaymentCardDetails">
                    <Controller
                      name="paymentCard"
                      control={control}
                      render={({ field }) => (
                        <CardElement
                          {...field}
                          options={{ hidePostalCode: true, ...cardOptions }}
                        />
                      )}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="email">{userProfile?.email}</div>
                  <div className="paymentCardDetails">
                    <div>
                      {userProfile?.stripe?.card
                        ? userProfile?.stripe?.card?.cardNumber
                        : "click edit and add card"}
                    </div>

                    <span
                      className="editMobile"
                      onClick={() => {
                        setEdit(true);
                        reset({ email: userProfile.email });
                      }}>
                      edit
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="actionButtons">
              {edit ? (
                <span
                  className="close"
                  onClick={() => {
                    newEmail
                      ? reset({
                          email: newEmail,
                        })
                      : reset({
                          email: userProfile?.email,
                        });
                    setEdit(false);
                  }}>
                  <AiOutlineClose />
                </span>
              ) : null}

              {edit ? <button type="submit">save</button> : <span></span>}
            </div>
          </div>
        </form>

        {userProfile?.stripe?.card ? (
          <PaymentButton
            hovercolor={publisherDetails?.primaryColor ?? "#4209EE"}
            bgcolor={publisherDetails?.primaryColor ?? "#4209EE"}
            onClick={() => {
              dispatch(
                makePayment({
                  data: { articleURL: articleURL },
                  setStep,
                  setIsScrolledDown,
                  url,
                }),
              );
            }}
            style={{ marginTop: "2rem", ...publisherButtonStyle }}>
            {loading ? (
              <div className="successLoader">
                <LoadingOutlined />
              </div>
            ) : (
              <div className="buttonTitle">
                <BiLockAlt className="lockIcon" />
                <div style={{ marginLeft: "2rem" }}>
                  Pay {currency}
                  {publisherDetails?.articlePrice}
                </div>
              </div>
            )}
          </PaymentButton>
        ) : isAvailable === false ? (
          <LoadingBar style={{ height: 50, width: 160 }} />
        ) : paymentRequest ? (
          <StripePayBtn>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </StripePayBtn>
        ) : (
          <PaySection>
            <PayWithCardDisabled>
              <img src="/icons/g-pay.svg" alt="" />
              <span> </span>
              <img src="/icons/ios-pay.svg" alt="" />
            </PayWithCardDisabled>
            <p>Unavailable in this browser.</p>
          </PaySection>
        )}
      </div>
      {!userProfile?.stripe?.card ? (
        <AliPay
          user={userProfile.id}
          publisher={publisherDetails.id}
          article={url}
          setStep={setStep}
          setIsScrolledDown={setIsScrolledDown}
          price={Number(publisherDetails?.articlePrice)}
          money={publisherDetails?.currency || "USD"}
          screen="finalize"
        />
      ) : null}
      {!userProfile?.stripe?.card ? (
        <WechatPay
          user={userProfile.id}
          article={url}
          setStep={setStep}
          setIsScrolledDown={setIsScrolledDown}
          publisher={publisherDetails.id}
          price={Number(publisherDetails?.articlePrice)}
          money={publisherDetails?.currency || "USD"}
          screen="finalize"
          notAvailable={userProfile?.stripe?.card !== ""}
        />
      ) : null}
    </FinalizeContent>
  );
};

const PaymentSuccess = ({
  setStep,
  setIsScrolledDown,
  publisherDetails,
  articleURL,
}) => {
  return (
    <PaymentSuccessForm
      setStep={setStep}
      setIsScrolledDown={setIsScrolledDown}
      publisherDetails={publisherDetails}
      articleURL={articleURL}
    />
  );
};

export default PaymentSuccess;
