import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { decode } from "js-base64";
import { sendToPublisherWebsite } from "./handleRedirection";
import { getPublisherDetails } from "../../redux/publisherSlice";
import { PaymentType, ClientMode, PaymentMethod } from "./paymentCconfig";
import { useDesktopCheck } from "../../hooks/useDesktopCheck";
import { useCountryCode } from "../../hooks/useCountryCode";
import { useCheckCountryAllow } from "../../hooks/useCheckCountryAllow";
import { ApiRequests } from "../../services/ApiRequest";
import "./../../../src/App.css";
import { convertCurrencyIntoUSD } from "../../utils/helper";

const WechatPay = ({
  price,
  money,
  user,
  publisher,
  article,
  setStep,
  setIsScrolledDown,
  screen,
}) => {
  const [secret, setSecretInformation] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(undefined);
  const dispatch = useDispatch();
  const publisherState = useSelector((state) => state.publisher);
  const [loading, setLoading] = useState(false);
  const code = useCountryCode();
  const countryCheckFlag = useCheckCountryAllow(code);

  const stripe = useStripe();
  const elements = useElements();
  const isDeskTop = useDesktopCheck();

  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  if (!countryCheckFlag) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const { convertedPrice, convertedCurrency } = await convertCurrencyIntoUSD(price, money);
    price = convertedPrice;
    money = convertedCurrency;
    let client_secret;
    if (!secret) {
      try {
        const tokenInfo = await ApiRequests.getPaymentIntentSecurityInfo({
          price,
          money,
          user,
          publisher,
          article: decode(article),
          method: PaymentMethod.wechat,
        });
        console.log("Token Info:", tokenInfo); // Debug log
        setSecretInformation(tokenInfo?.data?.data?.client_secret);
        client_secret = tokenInfo?.data?.data?.client_secret;
      } catch (error) {
        console.error("Error fetching client_secret:", error?.message); // Improved error logging
        setLoading(false);
        return;
      }
    } else {
      client_secret = secret;
    }

    if (!client_secret) {
      setLoading(false);
      console.error("Client secret is not defined.");
      return;
    }
    const parts = (await client_secret?.split("_")) || [];
    const pi = parts?.length ? parts[0] + "_" + parts[1] : "";

    // Construct the query string from `queryParams` object
    const returnUrl = encodeURI(
      `${process.env.REACT_APP_BASE_PATH}/stripe/payment-confirms/${pi}`,
    );
    console.log("Return URL:", returnUrl); // Debug log

    const { error, paymentIntent } = await stripe.confirmWechatPayPayment(
      client_secret,
      {
        payment_method_options: {
          wechat_pay: {
            client: isMobile ? ClientMode?.mobile : ClientMode?.web,
          },
        },
        return_url: returnUrl,
      },
      { handleActions: false },
    );

    if (error) {
      console.error("Error in confirming WeChat payment:", error); // Improved error logging
      setLoading(false);
      return;
    }

    const paymentUrl =
      paymentIntent?.next_action?.wechat_pay_display_qr_code?.data;
    const qrCodeImageDataUrl =
      paymentIntent?.next_action?.wechat_pay_display_qr_code?.image_data_url;
    const redirectUrl = paymentIntent?.next_action?.redirect_to_url?.url;
    console.log("Redirect URL:", redirectUrl); // Debug log
    console.log("QpaymentUrl:", paymentUrl); // Debug log
    setLoading(false);
    if (isMobile && redirectUrl) {
      window.open(redirectUrl, "_blank");
    } else {
      sendToPublisherWebsite(PaymentType.wechat, {
        qrCodeImageDataUrl,
        paymentUrl,
      });
      console.error("No redirect URL found.");
    }

    const intervalId = setInterval(async () => {
      try {
        await ApiRequests.transactionCheck({
          article: decode(article),
          user,
        });
        // setStep("signIn");
        // setIsScrolledDown(false);
        // // if(document && document.getElementById('myModal')) document.getElementById('myModal').style.display = "none";
        // localStorage.setItem("payId", user);

        // setTimeout(() => {
        //   dispatch(
        //     getPublisherDetails({
        //       publisherId: publisher,
        //       data: { articleURL: decode(article), userId: user },
        //     }),
        //   );
        // }, 500);
        // clearInterval(intervalId);
        setStep("signIn");
        setIsScrolledDown(false);
        localStorage.setItem("payId:", user);

        setTimeout(() => {
          dispatch(
            getPublisherDetails({
              publisherId: publisher,
              data: { articleURL: decode(article), userId: user },
            }),
          );
          setLoading(false);
          window.parent.postMessage(
            {
              purchased: publisherState?.publisherDetails?.purchased,
              postData: publisherState?.publisherDetails?.postData,
            },
            "*",
          );
        }, 2000);
        clearInterval(intervalId);
        setLoading(false); // Stop loading after successful check
      } catch (error) {
        setLoading(false);
        console.error("Error checking transaction:", error); // Improved error logging
      }
    }, 1500);
    setPollingInterval(intervalId);
  };

  const formInLineStyle = isDeskTop
    ? {
        position: "absolute",
        left: "422px",
        top: screen !== "finalize" ? "205px" : "218px",
      }
    : {
        position: "absolute",
        left: "5%",
        top: screen !== "finalize" ? "310px" : "320px",
      };

  const buttonStyle = isDeskTop
    ? {
        width: "160px",
        display: "flex",
        cursor: loading ? "not-allowed" : "pointer",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9px",
        background: "white",
        height: "50px",
        position: "relative",
      }
    : {
        padding: "5px",
        fontWeight: "500",
        height: "55px",
        cursor: loading ? "not-allowed" : "pointer",
        display: "flex",
        width: "90px",
        flexDirection: "column",
        background: "white",
        border: "2px solid black",
        borderRadius: "9px",
        position: "relative",
      };

  const fontStyle = isDeskTop
    ? { fontFamily: "Inter", marginTop: "13px" }
    : { fontFamily: "Inter", fontSize: "13px", fontWeight: "400" };

  const buttonInlineStyle = { width: "25px", height: "25px" };

  return (
    <form onSubmit={handleSubmit} style={formInLineStyle}>
      <button
        onClick={handleSubmit}
        disabled={!stripe || loading}
        style={buttonStyle}
        className="button">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <img
              src="/icons/wechat.png"
              style={buttonInlineStyle}
              alt="WeChat Pay"
            />
            <p style={fontStyle}>WeChat Pay</p>
          </>
        )}
      </button>
    </form>
  );
};

export default WechatPay;
