import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { decode } from "js-base64";

import { sendToPublisherWebsite } from "./handleRedirection";
import { getPublisherDetails } from "../../redux/publisherSlice";
import { PaymentType, PaymentMethod } from "./paymentCconfig";
import { useDesktopCheck } from "../../hooks/useDesktopCheck";
import { useCountryCode } from "../../hooks/useCountryCode";
import { useCheckCountryAllow } from "../../hooks/useCheckCountryAllow";
import { ApiRequests } from "../../services/ApiRequest";
import { convertCurrencyIntoUSD } from "../../utils/helper";

const AliPay = ({
  price,
  money,
  user,
  article,
  publisher,
  setStep,
  setIsScrolledDown,
  screen,
}) => {
  const [interval, setPollingInterval] = useState(undefined);
  const [secret, setSecretInformation] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const dispatch = useDispatch();
  const publisherState = useSelector((state) => state.publisher);

  const stripe = useStripe();
  const elements = useElements();
  const isDeskTop = useDesktopCheck();
  const code = useCountryCode();
  const checkCountryFlag = useCheckCountryAllow(code);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [interval]);

  if (!checkCountryFlag) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setLoading(true);
    const { convertedPrice, convertedCurrency } = await convertCurrencyIntoUSD(price, money);
    price = convertedPrice;
    money = convertedCurrency;
    let client_secret;
    if (!secret) {
      try {
        const tokenInfo = await ApiRequests.getPaymentIntentSecurityInfo({
          price,
          money,
          user,
          publisher,
          article: decode(article),
          method: PaymentMethod.alipay,
        });
        console.log("Token Info:", tokenInfo);
        setSecretInformation(tokenInfo?.data?.data?.client_secret);
        client_secret = tokenInfo?.data?.data?.client_secret;
      } catch (error) {
        console.error("Error fetching payment intent:", error.message);
        setLoading(false); // Stop loading on error
        return;
      }
    } else {
      client_secret = secret;
    }

    if (!client_secret) {
      console.error("No client secret available.");
      setLoading(false); // Stop loading if no client secret
      return;
    }

    const { error, paymentIntent } = await stripe.confirmAlipayPayment(
      client_secret,
      {
        return_url: `${process.env.REACT_APP_BASE_PATH}/stripe/payment-confirms`,
      },
      { handleActions: false },
    );
    console.log(paymentIntent);
    if (error) {
      console.error("Stripe payment error:", error);
      setLoading(false); // Stop loading on error
      return;
    }

    const gatewayRedirectLink =
      paymentIntent.next_action.alipay_handle_redirect.url;
    console.log("Redirecting to:", gatewayRedirectLink);
    sendToPublisherWebsite(PaymentType.alipay, gatewayRedirectLink);

    const intervalId = setInterval(async () => {
      try {
        const result = await ApiRequests.transactionCheck({
          article: decode(article),
          user,
        });
        console.log("Transaction check result:", result);
        setStep("signIn");
        setIsScrolledDown(false);
        localStorage.setItem("payId:", user);

        setTimeout(() => {
          dispatch(
            getPublisherDetails({
              publisherId: publisher,
              data: { articleURL: decode(article), userId: user },
            }),
          );
          setLoading(false);
          window.parent.postMessage(
            {
              purchased: publisherState?.publisherDetails?.purchased,
              postData: publisherState?.publisherDetails?.postData,
            },
            "*",
          );
        }, 2000);
        clearInterval(intervalId);
        setLoading(false); // Stop loading after successful check
      } catch (error) {
        setLoading(false);
        console.error("Error during transaction check:", error);
      }
    }, 7000);
    setPollingInterval(intervalId);
  };

  const formInLineStyle = isDeskTop
    ? {
        position: "absolute",
        left: "225px",
        top: screen !== "finalize" ? "205px" : "218px",
      }
    : {
        position: "absolute",
        left: "134px",
        top: screen !== "finalize" ? "310px" : "320px",
      };

  const buttonInlineStyle = {
    width: "25px",
    height: "25px",
  };

  const buttonStyle = isDeskTop
    ? {
        width: "160px",
        display: "flex",
        cursor: loading ? "not-allowed" : "pointer",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9px",
        background: "white",
        height: "50px",
        position: "relative", // Needed for loading bar positioning
      }
    : {
        padding: "5px",
        fontWeight: "500",
        height: "55px",
        cursor: loading ? "not-allowed" : "pointer",
        display: "flex",
        width: "90px",
        flexDirection: "column",
        background: "white",
        border: "2px solid black",
        borderRadius: "9px",
        position: "relative", // Needed for loading bar positioning
      };

  const fontStyle = isDeskTop
    ? { fontFamily: "Inter", marginTop: "13px" }
    : {
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "400",
      };

  return (
    <form onSubmit={handleSubmit} style={formInLineStyle}>
      <button
        onClick={handleSubmit}
        disabled={!stripe || loading}
        style={buttonStyle}
        className="button">
        {loading ? (
          <div className="spinner"></div> // Show the spinner when loading
        ) : (
          <>
            <img
              src="/icons/aliPay.png"
              style={buttonInlineStyle}
              alt="AliPay"
            />
            <p style={fontStyle}>Alipay</p>
          </>
        )}
      </button>
    </form>
  );
};

export default AliPay;
