import LoadingBar from "./loadingBar";

const StripeSkeleton = () => {
  const layers = [
    { width: "160px", height: 50 },
    { width: "230px", height: 90 },
    { width: "130px", height: 40 },
  ];

  const skeletonMapLayers = layers.map((layer, index) => (
    <LoadingBar key={index} count={1} style={layer} />
  ));

  const inlineStyle = {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    gap: "20px",
  };

  return <div style={inlineStyle}>{skeletonMapLayers}</div>;
};

export default StripeSkeleton;
