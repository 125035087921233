import React, { useEffect, useRef, useState } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { Button, Col, Input, Row, notification } from "antd";
import intlTelInput from "intl-tel-input";
import { LoadingOutlined } from "@ant-design/icons";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import {
  saveCardTokenDetails,
  sendPaymentToken,
  setCurrentStep,
  setEditDetails,
  setNewEmail,
  setTokenSent,
  signin,
  updateUserProfile,
} from "../../redux/authSlice";

import StripeSkeleton from "../stripeSkeleton";
import { PaymentSignupContent } from "./index.style";
import usePaymentCardOptions from "../../hooks/usePaymentCardOptions";

import { sendEvent } from "../../services/amplitude";
import { AMPLITUDE_EVENTS } from "../../utils/text";
import useFirstLogin from "../../hooks/useFirstLogin";

const PaymentSignupForm = ({ setStep, setUserPhoneNumber }) => {
  const phoneInputRef = useRef(null);
  const iti = useRef(null);
  const [edit, setEdit] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const cardOptions = usePaymentCardOptions();
  const dispatch = useDispatch();
  const { loading, userProfile, editDetails } = useSelector(
    (state) => state?.auth,
  );

  const { cardTokenDetails, tokenSent, newEmail } = useSelector(
    (state) => state.auth,
  );

  const userinfo = localStorage.getItem("authUser");
  const user = userinfo ? JSON.parse(userinfo) : userinfo;
  const [api, contextHolder] = notification.useNotification();

  const isFirstLogin = useFirstLogin(
    userProfile,
    cardTokenDetails,
    setStep,
    tokenSent,
  );
 console.log("isFirstLogin",isFirstLogin)
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    reset: reset2,
  } = useForm();

  useEffect(() => {
    if (!isFirstLogin) {
      const inputElement = phoneInputRef.current;
      iti.current = intlTelInput(inputElement, {
        initialCountry: "auto",
        preferredCountries: ["us", "gb", "ca", "ch"],
        autoInsertDialCode: true,
        geoIpLookup: (callback) => {
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((data) => callback(data.country_code))
            .catch(() => callback("us"));
        },
      });

      // Cleanup when the component unmounts
      return () => {
        iti.current.destroy();
      };
    }
  }, []);

  useEffect(() => {
    reset2({
      email: userProfile?.email,
    });
  }, [reset2, userProfile?.email]);

  const onSubmitPhone = (data) => {
    const countryDialCode = iti.current.getSelectedCountryData().dialCode;

    if (!countryDialCode) {
      notification.error({
        message: "Please select a valid country code",
      });
      return;
    }

    const phoneNumber = `+${countryDialCode}${data.phone}`;
    setUserPhoneNumber(phoneNumber);

    sendEvent(AMPLITUDE_EVENTS.ENTERED_AUTH);

    dispatch(setCurrentStep("paymentSignup"));

    if (!tokenSent) {
      if (userProfile?.phone === phoneNumber) {
        dispatch(
          signin({
            loginData: { email: userProfile?.email, phone: phoneNumber },
            setStep,
            api,
          }),
        );
      } else {
        dispatch(
          sendPaymentToken({
            data: {
              name: userProfile?.name || user?.name,
              userId: userProfile?.id,
              cardToken: cardTokenDetails?.id,
              phone: phoneNumber,
            },
            setStep,
            api,
          }),
        );
      }
    } else {
      if (userProfile?.phone === phoneNumber) {
        dispatch(
          signin({
            loginData: { email: userProfile?.email, phone: phoneNumber },
            setStep,
            api,
          }),
        );
      } else {
        dispatch(
          sendPaymentToken({
            data: {
              name: userProfile?.name || user?.name,
              userId: userProfile?.id,
              phone: phoneNumber,
            },
            setStep,
            api,
          }),
        );
      }
    }
  };

  const onSubmitEmailCard = async (formData) => {
    const data = {
      ...formData,
      email:
        userProfile?.email === formData?.email ? undefined : formData?.email,
    };

    const { cardHolderName, ...oldCardTokenData } = cardTokenDetails;

    dispatch(setEditDetails(false));

    dispatch(setNewEmail(data?.email));

    const { token } = await stripe.createToken(
      elements.getElement(CardElement),
    );

    if (token) {
      sendEvent(AMPLITUDE_EVENTS.ENTERED_AUTH);
      dispatch(setTokenSent(false));
      dispatch(saveCardTokenDetails({ cardHolderName, ...token, ...data }));
      let updateData = {
        cardToken: token.id,
      };
      if (data?.email !== userProfile?.email) {
        updateData = {
          ...updateData,
          email: data?.email,
        };
      }

      dispatch(updateUserProfile({ data: updateData, setStep, api }));
      setEdit(false);
    }
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  return isFirstLogin ? (
    <StripeSkeleton />
  ) : (
    <PaymentSignupContent>
      {contextHolder}
      <h3>Authenticate</h3>
      <form onSubmit={handleSubmit2(onSubmitEmailCard)}>
        <div className="paymentCard">
          <div className="labelHedings">
            <label>Email</label>
            <label>Payment</label>
          </div>
          <div className={`${edit ? "paymentEdit" : "paymentData"} `}>
            {edit ? (
              <>
                <div className="editEmail">
                  <Controller
                    name="email"
                    control={control2}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="email"
                        className="updateEmail"
                        placeholder="your@email.com"
                      />
                    )}
                  />
                </div>
                <span className="divider"></span>
                <div className="editPaymentCardDetails">
                  <Controller
                    name="paymentCard"
                    control={control2}
                    render={({ field }) => (
                      <CardElement
                        {...field}
                        options={{ hidePostalCode: true, ...cardOptions }}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="email">{newEmail ?? userProfile?.email}</div>
                <div className="paymentCardDetails">
                  {/* <img src='/icons/card.svg' alt='' />{" "} */}
                  <div>•••• •••• •••• {cardTokenDetails?.card?.last4} </div>
                  {!userProfile?.stripe?.customer_id ? (
                    <span className="editMobile" onClick={() => setEdit(true)}>
                      edit
                    </span>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <div className="actionButtons">
            {edit ? (
              <span
                className="close"
                onClick={() => {
                  reset2({
                    email: userProfile?.email,
                  });
                  setEdit(false);
                }}>
                <AiOutlineClose />
              </span>
            ) : (
              !userProfile?.stripe?.customer_id && (
                <span className="edit" onClick={() => setEdit(true)}>
                  edit
                </span>
              )
            )}

            {edit ? <button type="submit">save</button> : <span></span>}
          </div>
        </div>
      </form>
      <form onSubmit={handleSubmit(onSubmitPhone)}>
        <Row gutter={16}>
          <Col md={{ span: 8 }} xs={{ span: 24 }}>
            <Input.Group compact style={{ overflow: "visible" }}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                  required: "Phone number is required",
                  validate: {
                    numeric: (value) => {
                      if (!isNumeric(value)) {
                        return "Phone number must contain only numeric digits";
                      }
                      return true;
                    },
                  },
                }}
                render={({ field }) => {
                  return (
                    <input
                      {...field}
                      type="tel"
                      ref={phoneInputRef}
                      placeholder="Phone Number"
                    />
                  );
                }}
              />

              <Button
                type="submit"
                htmlType="submit"
                style={{ padding: 0, marginRight: 20 }}>
                {loading ? <LoadingOutlined /> : <BsArrowRightCircle />}
              </Button>
            </Input.Group>
            {errors?.phone && <p className="error">{errors?.phone?.message}</p>}
          </Col>
        </Row>
      </form>
      <div className="viaSMS">
        <span>To authenticate you via SMS</span>
      </div>
      <span
        style={{ cursor: "pointer" }}
        className="password"
        onClick={() => {
          sendEvent(AMPLITUDE_EVENTS.ENTERED_AUTH);
          dispatch(setCurrentStep("paymentSignup"));
          if (!tokenSent) {
            if (!editDetails) {
              let userinfo =
                localStorage.getItem("authUser") &&
                JSON.parse(localStorage.getItem("authUser"));
              if (userinfo && userinfo.stripe && !userinfo.token) {
                dispatch(
                  sendPaymentToken({
                    data: {
                      userId: userProfile?.id,
                      name: (userProfile?.name || user?.name)==null?userinfo.email:(userProfile?.name || user?.name),
                      cardToken: userProfile?.stripe.customer_id,
                      email: newEmail,
                      sendEmailOtp: true,
                    },
                    setStep,
                    api,
                  }),
                );
              } else {
                dispatch(
                  sendPaymentToken({
                    data: {
                      userId: userProfile?.id,
                      name: userProfile?.name || user?.name,
                      cardToken: cardTokenDetails?.id,
                      email: newEmail,
                      sendEmailOtp: true,
                    },
                    setStep,
                    api,
                  }),
                );
              }
              dispatch(setEditDetails(true));
            } else {
              dispatch(
                signin({
                  loginData: { email: userProfile?.email },
                  setStep,
                  api,
                }),
              );
            }
          } else {
            dispatch(
              sendPaymentToken({
                data: {
                  userId: userProfile?.id,
                  name: userProfile?.name==null?"":userProfile?.name,
                  sendEmailOtp: true,
                  email: newEmail,
                },
                setStep,
                api,
              }),
            );
          }
        }}>
        Click here for an email confirmation instead
      </span>
    </PaymentSignupContent>
  );
};

const PaymentSignup = ({ setStep, setUserPhoneNumber, setIsScrolledDown }) => {
  return (
    <PaymentSignupForm
      setStep={setStep}
      setIsScrolledDown={setIsScrolledDown}
      setUserPhoneNumber={setUserPhoneNumber}
    />
  );
};

export default PaymentSignup;
